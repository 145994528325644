import React from "react";
import { useEffect } from 'react';
export default function About() {
  useEffect(() => {
    document.title ='About Us'; // Set your default title here
  });
  return (
    <div>
        
        <section class="about_section layout_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="detail-box">
                <div class="heading_container">
                  <h2>About Us</h2>
                </div>
                <p>
                  Welcome to Psoft – Your Destination for Tailored Software
                  Solutions. At Psoft, we're have expertise in custom software
                  development, web and ecommerce solutions, and more. As your
                  trusted partner in the digital realm, we're here to fuel your
                  innovation. Explore our comprehensive range of services and
                  embark on a journey of technological excellence with Psoft by
                  your side,
                </p>
              </div>
            </div>
            <div class="col-md-6 ">
              <div class="img-box">
                <img src="images/about-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  )
}
